import { Routes, Route, useLocation } from 'react-router-dom';
import routes, { RouteType } from '@utils/routes';
import { useMoralis } from 'react-moralis';
import { useEffect } from 'react';
import { useInsertAllUserFailedTransactionsMutation } from '@store/api/utilApi';
import useUser from '@hooks/useUser';
import Default from '@pages/Default';
import { Box } from '@mui/material';
import useUtils from '@hooks/useUtils';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { toRem } from '@utils/functions';
import AuthGuard from './guards/AuthGuard';

function App() {
  const { enableWeb3, isWeb3EnableLoading, isWeb3Enabled } = useMoralis();
  const location = useLocation();
  const { currentUser } = useUser();
  const { logOut } = useUtils();

  const handleLogOut = async () => {
    await logOut();
  };

  const [insertAllUserFailedTransactions] =
    useInsertAllUserFailedTransactionsMutation();

  const displayPage = ({ Component, isProjected }: RouteType) => {
    switch (true) {
      case currentUser &&
        !currentUser?.isWhitelisted &&
        location.pathname !== '/':
        return (
          <Box sx={{ height: '100vh' }}>
            <Default
              fullHeight
              text="You are not authorized to view this website"
            >
              <ButtonGradientDark
                sx={{ marginTop: toRem(18), width: 'unset' }}
                onClick={handleLogOut}
              >
                Logout
              </ButtonGradientDark>
            </Default>
          </Box>
        );
      case isProjected:
        return (
          <AuthGuard>
            <Component />
          </AuthGuard>
        );

      default:
        return <Component />;
    }
  };

  useEffect(() => {
    if (currentUser) {
      // TODO: handle the loading state
      insertAllUserFailedTransactions(currentUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const handleEnableWeb3 = async () => {
      await enableWeb3();
    };
    if (!isWeb3Enabled && !isWeb3EnableLoading && location.pathname !== '/') {
      handleEnableWeb3();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <Box sx={{ minHeight: '100vh', display: 'flex', '&>*': { width: '100%' } }}>
    <Routes>
      {routes.map((route) => {
        const Page = displayPage(route);
        return <Route key={route.path} path={route.path} element={Page} />;
      })}
    </Routes>
    // </Box>
  );
}

export default App;
